import React from "react";
import { Link } from "gatsby";
import cca1 from "../assets/images/09.jpg";
import cca2 from "../assets/images/7.jpg";
import cca3 from "../assets/images/claymaking_8.png";
import cca4 from "../assets/images/01.png";
import cca5 from "../assets/images/birth.png";
import cca6 from "../assets/images/classstudent.jpg";
import cca7 from "../assets/images/interhouse.png"
const Cca = () => {
  return (
    <section className="blog-one blog-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={cca1} alt="" />
              </div>
              <div className="blog-one__content text-center">
                <h2 className="blog-one__title">
                  <Link to="/news-details">
                  @ All about
                  </Link>
                </h2>
                <p className="blog-one__text">
                domestic, wild, aquatic
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={cca2} alt="" />
              </div>
              <div className="blog-one__content text-center">
                <h2 className="blog-one__title">
                  <Link to="/news-details">
                  @ VR Mall
                  </Link>
                </h2>
                <p className="blog-one__text">
                Christmas 
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={cca3} alt="" />
              </div>
              <div className="blog-one__content text-center">
                <h2 className="blog-one__title">
                  <Link to="/news-details">
                  G1&2
                  </Link>
                </h2>
                <p className="blog-one__text">
                Clay Making Activity
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={cca4} alt="" />
              </div>
              <div className="blog-one__content text-center">
                <h2 className="blog-one__title">
                  <Link to="/news-details">
                  GANESH CHATURTHI CELEBRATION (ONLINE)
                  </Link>
                </h2>
                <p className="blog-one__text">
                (04-09-2021)
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={cca5} alt="" />
              </div>
              <div className="blog-one__content text-center">
                <h2 className="blog-one__title">
                  <Link to="/news-details">
                  GRAND OPENING OF GRADE IX-XII
                  </Link>
                </h2>
                <p className="blog-one__text">
                (01-09-2021)
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={cca6} alt="" />
              </div>
              <div className="blog-one__content text-center">
                <h2 className="blog-one__title">
                  <Link to="/news-details">
                  Hindi Handwriting Competition
                  </Link>
                </h2>
                <p className="blog-one__text">
                (21-09-2021)
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-one__single">
              <div className="blog-one__image">
                <img src={cca7} alt="" />
              </div>
              <div className="blog-one__content text-center">
                <h2 className="blog-one__title">
                  <Link to="/news-details">
                  Inter House Competition
                  </Link>
                </h2>
                {/* <p className="blog-one__text">
                (21-09-2021)
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <a href="#none" className="thm-btn">
          Show Me More
        </a>
      </div>
    </section>
  );
};

export default Cca;
