import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Cca from '../components/Cca';
import Topbar from '../components/Topbar';

const CcaPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | CCA">
            <Topbar />
            <NavOne />
            <PageHeader title="CCA" />
            <Cca />
            <Footer />
        </Layout>
    );
};

export default CcaPage;
